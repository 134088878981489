<template>
<div id="orderManage">
  <!--头部搜索-->
  <div class="bg-white searchDom">
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="searKeyword" v-model="sendData.keyword" placeholder="请输入ID查询" suffix-icon="search"></el-input>
        <i style="cursor: pointer;" @click="searKeyword" class="el-icon-search"></i>
      </div>
      <el-button  style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;" @click="addCategory_">添加分类</el-button>
      <el-button  style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;" @click="addQue">添加问题</el-button>
      <el-button  style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;" @click="exportData">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <ul class="row margin-bottom-16 flex-start">
      <li v-for="(v,i) in dataList" :key="i">
			  <div class="fj_post">
			  	<div class="details" style="height:270px">
            <p class="flex justify-between">ID：<span>{{v.qa_id}}</span></p>
            <p class="flex justify-between">分类：<span>{{v.qa_category_title}}</span></p>
			  		<p class="flex align-center">问题：
              <span style="width:150px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;display: inline-block;margin-left:30px">{{v.qa_q}}</span>
              <el-button @click="readInfo(v.qa_q,'问题')" type="text" size="medium" style="color: #4E9F5B;cursor:pointer;position: absolute;right:0">查看
            </el-button>
            </p>
            <p class="flex  justify-between">答案：<span @click="readInfo(v.qa_a,'答案',v.qa_file_url)" style="color: #4E9F5B;cursor:pointer;position: absolute;right:0">查看</span></p>
			  		<p class="flex justify-between">发布时间：<span>{{v.qa_create_time*1000|getDate}}</span></p>
            <p class="flex  justify-between">状态：<span>{{v.qa_state=='1'?'启用':'未启用'}}</span></p>
            <p>
             <span @click="editQuestion(v)" style="color: #4E9F5B;cursor:pointer;margin-left:20px">编辑</span>
             <span @click="delQuestion(v.qa_id)" style="color: #4E9F5B;cursor:pointer">删除</span>
            </p>
			  	</div>
			  </div>
		  </li> 
    </ul>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getTabList"
      :page.sync= "sendData.page"
      :limit.sync="sendData.limit"/>
  </div>
  <!-- 表格弹窗 -->
  <!-- 地址表格弹窗 -->
    <el-dialog
    id="infoModelBox"
    :visible.sync="showInfoModel"
    :close-on-press-escape='false'
    :close-on-click-modal='false'
    :title="title"
    close="closeInfoModel"
    >

    <div  class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left" style="min-height: 225px;">
      <div v-html="content"></div>
    </div>

  </el-dialog>

  <el-dialog id="imgModelBox" close="closeImgModel" :visible.sync="showImgModel" title="查看附件" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body>

    <div class="spadding-16 text-14 text-333 margin-top-24 text-left flex flex-wrap" style="min-height: 225px;">
      <div v-for="(item,index) in imgContent" :key="index">
        <img :src="item" style="margin-bottom: 16px;margin-right: 10px;width: 120px;height: 120px;" >
      </div>
    </div>

  </el-dialog>

  <!-- 编辑弹框 -->
  <el-dialog id="question" :close="closeEditModel" title="编辑问题" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="showEditModel">
    <div class="margin-top-24">
      <el-form :model="editDataInfo" :rules="rules" ref="editDataInfo" label-width="100px" label-position="left"
        style="text-align: left" class="demo-ruleForm">
        <el-form-item label="问答分类" prop="qa_category_id">
          <el-select v-model="editDataInfo.qa_category_id" placeholder="请选择问答分类">
            <el-option v-for="(item, index) in qaType" :key="index" :label="item.qa_category_title"
              :value="item.qa_category_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题" prop="qa_q">
          <el-input type="text" v-model="editDataInfo.qa_q" placeholder="请输入问题"></el-input>
        </el-form-item>
        <el-form-item label="编辑答案">
          <Editor :catchData="editorChange" ref="mychild" :content="editDataInfo.qa_a"></Editor>
        </el-form-item>
        
        <el-form-item label="状态" prop="qa_state">
          <el-switch v-model="editDataInfo.qa_state" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>

    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click.stop="sendEditQuestion">修 改</el-button>
    </span>
  </el-dialog>

  <!-- 添加分类 -->
    <!-- 添加公告弹窗 -->
  <el-dialog id="addCategory" title="添加问题" :visible.sync="showAddQesModel" @close="closeAddDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
      <div id="head_in" class="flex align-center justify-start margin-top-24">
        <el-input v-model="input_title" placeholder="请输入内容" style="width: 50%;"></el-input>
        <el-button type="success" @click="addType"
          style="height: 32px;background-color: #4E9F5B;padding: 0 10px;margin-left: 16px;">添加
        </el-button>
      </div>
      <div class="margin-top-24">
         <el-table
            :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="qaType" stripe
            style="width: 100%">
            <el-table-column prop="qa_category_id" label="分类ID" align="center">
            </el-table-column>
            <el-table-column prop="qa_category_title" label="分类名称" align="center">
            </el-table-column>
            <el-table-column prop="qa_count" label="文章数量" align="center">
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="editCategory(scope.row.qa_category_id)" type="text" size="small" style="color: #4E9F5B;">编辑
                </el-button>
                <el-button @click="delCategory(scope.row.qa_category_id)" type="text" size="small" style="color: #FF1A2E;">删除
                </el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
  </el-dialog>
  <!--添加问题 -->
  <el-dialog id="question" title="发布问题" close="closeAddQesModel" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="showAddQesModel">
    <div id="editQa" class="margin-top-24">
      <el-form :model="ruleForm1" :rules="rules" ref="ruleForm1" label-width="100px" label-position="left"
        style="text-align: left" class="demo-ruleForm" >
        <el-form-item label="问答分类" prop="qa_category_id">
          <el-select v-model="ruleForm1.qa_category_id" placeholder="请选择问答分类">
            <el-option v-for="(item, index) in qaType" :key="index" :label="item.qa_category_title"
              :value="item.qa_category_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题" prop="qa_q">
          <el-input type="text" v-model="ruleForm1.qa_q" placeholder="请输入问题"></el-input>
        </el-form-item>
        <el-form-item label="编辑答案">
          <Editor2 :catchData="editorChange" ref="mychild" :content="content"></Editor2>
        </el-form-item>
        <el-form-item label="状态" prop="qa_state">
          <el-switch v-model="ruleForm1.qa_state" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click.stop="sendAddQue('ruleForm1')">发 布</el-button>
    </span>
  </el-dialog>

  <!-- 添加分类 -->
  <el-dialog id="addCategory" title="添加分类" :visible.sync="showFenLeiModel" @close="closeAddDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
      <div id="head_in" class="flex align-center justify-start margin-top-24">
        <el-input v-model="input_title" placeholder="请输入内容" style="width: 50%;"></el-input>
        <el-button type="success" @click="addType"
          style="height: 32px;background-color: #4E9F5B;padding: 0 10px;margin-left: 16px;">添加
        </el-button>
      </div>
      <div class="margin-top-24">
         <el-table
            :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="qaType" stripe
            style="width: 100%">
            <el-table-column prop="qa_category_id" label="分类ID" align="center">
            </el-table-column>
            <el-table-column prop="qa_category_title" label="分类名称" align="center">
            </el-table-column>
            <el-table-column prop="qa_count" label="文章数量" align="center">
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="editCategory(scope.row.qa_category_id)" type="text" size="small" style="color: #4E9F5B;">编辑
                </el-button>
                <el-button @click="delCategory(scope.row.qa_category_id)" type="text" size="small" style="color: #FF1A2E;">删除
                </el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
  </el-dialog>
</div>
</template>
<script>
import {question,questionAdd,questionDel,questionEdit,
questionCategory,questionCategoryAdd,
questionCategoryDel,questionCategoryEdit} from '@/api/http'
import Pagination from '@/components/pagination'
export default {
    data(){
      return{
        dataList: [], //表格数据
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
       /* 查看附件弹窗*/
        showImgModel:false,
        showInfoModel:false,
        sendData:{
          qa_category_type:2,//1 小程序 2 站长H5
          keyword:'',
          page:1,
          limit:8
        },
        title:'',
        rules: {
          qa_category_id: [{
            required: true,
            message: "请选择问答分类",
            trigger: "change",
          }, ],
          qa_q: [{
            required: true,
            message: "请输入问题",
            trigger: "blur",
          }, ],
          qa_a: [{
            required: true,
            message: "请输入答案",
            trigger: "blur",
          }, ],
        },
        showEditModel:false,
        content:'',
        imgContent:'',
        editDataInfo:{},
        qaType:[],
        ruleForm:{},
        rules:{},
        showFenLeiModel:false,
        input_title:'',
        categoryTypeList:[],
        showAddQesModel:false,
        ruleForm1:{},
        content_nav:''//富文本内容
      }
    },
    created(){
      this.getTabList()//小程序列表数据
      this.getTypeList()
    },
    methods:{
      addQue(){
        this.showAddQesModel = true
      },
      sendAddQue(formName){
        this.ruleForm1.qa_a = this.content_nav
        this.$refs[formName].validate((valid)=>{
          if(valid){
            console.log(this.ruleForm1)
            questionAdd(this.ruleForm1).then(res=>{
              console.log('添加问题：',res.data)
              if(res.data.code == '1'){
                this.$message.success('发布成功')
                this.content = this.content_nav = ''
                this.$refs[formName].resetFields()
              }else{
                this.$message.error(res.info);
              }
            })
          }else{
            console.log('error submit')
            return false
          }
        })
      },
      //富文本返回的编辑内容
      editorChange(info) {
        console.log('富文本返回',info)
        this.content_nav=info
      },

      catchData_(value) {
        this.content = value //在这里接受子组件传过来的参数，赋值给data里的参数
      },
      closeAddDialog(){
        this.showFenLeiModel = false
        this.getTabList()
      },
      closeAddQesModel(){
        this.showAddQesModel = false
        this.getTabList()
      },
      addType(){
        if(this.input_title == ''){
          return false
        }
        let that = this
        questionCategoryAdd({
          qa_category_type: 1,
          qa_category_title: this.input_title
        }).then(res => {
          console.log('添加小程序新闻分类',res.data)
          if (res.data.code == '1') {
            this.$message.success('添加成功')
            this.showFenLeiModel = false
          } else {
            this.$message.error(res.info)
          }
        })
      },
      /* 编辑分类*/
      editCategory(id) {
        this.$prompt('请输入新的分类名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({
          value
        }) => {
          questionCategoryEdit({
            qa_category_id:id,
            qa_category_title:value}).then(res=>{
            console.log('编辑小程序分类',res.data)
            if(res.data.code=='1'){
              this.$message.success('编辑成功')
              this.showFenLeiModel = false
            }else{
              this.$message.error(res.info)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      },
      /* 删除分类*/
      delCategory(id) {
        let that = this
        this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          questionCategoryDel({
             qa_category_id: id
          }).then(res => {
            console.log('删除小程序分类：',res.data)
          if (res.data.code == '1') {
            this.$message.success('删除成功')
            this.showFenLeiModel = false
          } else {
            this.$message.error(res.info)
          }
        })
          // this.$emit('reFresh')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      getTypeList(type){
        questionCategory({qa_category_type:2}).then(res=>{
          console.log('type',res.data)
          if(res.data.code == '1'){
            this.qaType = res.data.data.list
          }
        })
      },
      //获取意见反馈列表
      getTabList(){
        question(this.sendData).then(res=>{
          console.log('小程序列表'+res.data.data)
          let {list,page,count,limit}=res.data.data
          if(res.data.code == '1' && list){
            this.rows = count
            this.dataList = list
          }else if(res.data.code=='1'&&res.data.data.csv_file){
            window.open(res.data.data.csv_file)
            this.sendData.limit=this.rows
          }
        })
      },
      addCategory_(){
        this.showFenLeiModel = true
        this.getTypeList()
      },
      sendEditQuestion(){
        questionEdit(this.editDataInfo).then(res=>{
          if(res.data.code == '1'){
            this.$message.success('修改成功')
            this.showEditModel = false
            this.editDataInfo = {}
            this.getTabList()
          }else{
            this.$message.error(res.info)
          }
        })
      },
      editQuestion(info){
        let that = this
        this.editDataInfo = info
        console.log(info)
        this.qaType.forEach(item=>{
          if(item.qa_category_title==that.editDataInfo.qa_category_title){
            that.editDataInfo.qa_category_id=item.qa_category_id
          }
        })
        this.showEditModel = true
      },
      /* 删除*/
      delQuestion(id) {
        this.$confirm('是否确定删除该问答?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          questionDel({qa_id:id}).then(res=>{
            if(res.data.code == '1'){
              this.$message.success('删除成功')
              this.getTabList()
            }else{
              this.$message.error(res.info)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      readInfo(info, title,imgarr) {
        this.showInfoModel = true
        this.title = title
        this.imgArr = imgarr
        this.content = info
      },
      readImg(content){
        this.showImgModel = true
        this.imgContent = content
      },
      //搜索关键字
      searKeyword(){
        // this.getTabList()
        console.log(this.sendData.keyword)
        if(!this.sendData.keyword) return this.getTabList();
        // return this.dataList.filter(v=>{
        //   console.log(v)
        //   return v.coupon_title.includes(this.sendData.keyword);
        // })
        var _this = this
        var newItems = []
        this.dataList.map(function(item){
          // console.log(item)
          if(!item.qa_id.search(_this.sendData.keyword)){
            console.log(item)
            newItems.push(item)
          }
        })
        // return newItems
        console.log(newItems)
        this.dataList = newItems
      },
      /* 清空输入框 */
      emptyKeyword(e){
        if(e.length=='0'){
          this.sendData.page=1
          this.getTabList()
        }
      },
      closeAddModel(){
        this.showFenLeiModel = false
      },
      //导出
      exportData(){
        console.log('sss')
        let obj={
          qa_category_type: 1,
          page: 1,
          limit: 9999,
          qa_category_id: '',//分类id，非必传
          keyword:999,
        }
        for (let item in obj) {
          if(obj[item]==''||obj[item]=='999'){
            delete obj[item]
          }
        }
        question(obj).then(res=>{
          console.log('导出表格',res)
          if(res.data.code=='1'&&res.data.csv_file){
            window.open(res.data.csv_file)
          }
        })
        // this.getTabList()
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      // 关闭弹窗
      closeEditModel() {
        this.showEditModel = false
        this.getTabList()
      },
      closeImgModel() {
        this.showImgModel = false
        this.getTabList()
      }
    },
    components:{
      Pagination
    }
}
</script>
<style>
#orderManage{
  width: 100%;
}
#orderManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
} 
#orderManage .searchDom{
    height: 70px;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 24px 80px 4px 24px;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 60px;
    right: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
}
#orderManage .searchList{
  padding: 16px;
  margin-top: 76px;
}  
#orderManage ul{
  list-style: none;
  margin-left: -16px;
  margin-right: 16px;
}
#orderManage .searchList li{
  width: 19%;
  margin-right: 15px;
}
#orderManage ul li .order_number{
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#orderManage ul li .order h1 {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  display: block;
  margin-top: 2px;
}
#orderManage ul li .order font {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  display: block;
  float: right;
}
.order #order_state{
  margin-top: -15px;
}
.order span{
    display: block;
    white-space: nowrap;
    color: #323233;
    font-size: 14px;
    font-weight: bold;
}
.order em{
  display: block;
  white-space: nowrap;
  color: #323233;
  font-size: 14px;
}
.order p {
    color: #323233;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.order .order_kd{
  padding: 10px 14px;
  background: rgba(235,237,240,.3);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 20px;
}
.order .order_kd img{
  display: block;
  width: 80px;
  height: 36px;
  object-fit: cover;
  background: #ebedf0;
}
.order .order_kd i{
  display: block;
  width: 1px;
  height: 17px;
  background: #ebedf0;
  margin: 0 16px;
}
.order .order_kd .copy{
  display: block;
  white-space: nowrap;
  color: #79838b;
  font-size: 14px;
}
.pageNum {
    height: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: fixed;
    z-index: 20;
    left: 144px;
    right: 28px;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #000;
    font-size: 14px;
}
.fj_post {
    background-color: #ffffff;
    border: 1px solid #f6f6f6;
    border-left: 4px solid transparent;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 30px;
    /**height: 350px;**/
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
}
.fj_post .details{
  margin-top: 45px;
  margin-left: 15px;
}
.fj_post .thumb {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    float: left;
    margin-right: 30px;
    text-align: center;
}

.fj_post .details {
    flex: 1;
}
.fj_post .details p {
    margin-bottom: 10px;
    color: #6d6d6d !important;
    font-size: 14px;
    position: relative;
}
.fj_post .details span{margin-right: 5px;}
.fj_post:hover {
    border: 1px solid #345e3b;
    border-left: 4px solid #345e3b;
}

</style>